import React, {useRef} from "react";
import Layout from "@components/layout";
import {useIsVisible} from "react-is-visible";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "use-media-query-hook";

const headers = [
	{
		name: "Overview",
		href: "overview",
	},
	{
		name: "Definition",
		href: "definition",
	},
	{
		name: "Our Privacy Principles",
		href: "ourPrivacyPrinciples",
	},
	{
		name: "Personal Information We May Collect",
		href: "personalInformationWeMayCollect",
	},
	{
		name: "How we get your Personal Information",
		href: "howWeMayGetYourPersonalInfo",
	},
	{
		name: "How We May Use Your Personal Information",
		href: "howWeMayUseYourPersonalInformation",
	},
	{
		name: "Usage of Collected Contact List",
		href: "usageofCollectedContactList",
	},
	{
		name: "Data Security and Retention",
		href: "dataSecurityAndRention",
	},
	{
		name: "Cookies",
		href: "cookies",
	},
	{
		name: "Updates to our privacy policy",
		href: "updatesToOurPrivacyPolicy",
	},
	{
		name: "Contact Us",
		href: "contactUs",
	},
];

const PrivacyPage = ({location}) => {
	const headerRef = useRef();
	const isHeaderVisible = useIsVisible(headerRef);

	const bottomRef = useRef();
	const isBottomVisible = useIsVisible(bottomRef);

	const params = new URLSearchParams(location.search);
	const hideHeader = params.get("hide_header");
	const hideFooter = params.get("hide_footer");
	const hideChat = params.get("hide_chat");

	const isMobile = useMediaQuery("(max-width: 850px)");

	return (
		<Layout
			pageTitle="Privacy Policy"
			hideHeader={hideHeader === "true"}
			hideFooter={hideFooter === "true"}
			hideChat={hideChat === "true"}
			footerRef={bottomRef}
			headerBg="bg-purple-50">
			<section className="relative bg-purple-50 py-5" ref={headerRef}>
				<div className="relative container pt-12 px-4 mb-20 mx-auto text-center">
					<h2 className="mt-8 mb-8 lg:mb-12 text-3xl lg:text-4xl font-semibold">
						Platnova Privacy Policy
					</h2>
				</div>
			</section>

			<section className="relative">
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap -mx-3">
						{hideHeader !== "true" && (
							<div className="w-full sm:w-1/3 px-3 pt-10 lg:pt-20 pb-10">
								<ul
									className={`mr-4 md:mr-0 mb-4 
								${!isMobile ? (isHeaderVisible ? "" : "fixed top-20") : ""} 
								${isBottomVisible ? "hidden" : ""}`}>
									{headers.map((item, index) => (
										<li className="mb-5" key={index}>
											<AnchorLink
												offset="80"
												className="inline-block pl-4 text-sm font-medium"
												href={`#${item.href}`}>
												{item.name}
											</AnchorLink>
										</li>
									))}
								</ul>
							</div>
						)}

						<div
							className={`w-full ${
								hideHeader !== "true" ? "sm:w-2/3" : "sm:w-3/3"
							}  px-3`}>
							<div className="flex flex-wrap px-3">
								<div className="mt-8 mb-8">
									We are Platnova Inc., a Delaware corporation (company number
									7009837) with our headquarters located at 651 N Broad St,
									Suite 201, Middletown, DE 19709 USA. Platnova Inc. is a global
									technology company that offers secure and seamless solutions
									for bill payment, currency swapping, depositing, and money
									transfer worldwide. We also operate as Platnova LTD in Canada,
									Rwanda, United Kingdom, and as Platnova Technologies LTD in
									Nigeria.
								</div>

								<div className="mb-8">
									Platnova and its affiliates (collectively referred to as
									Platnova), we greatly value the trust you place in us by
									sharing your information. We prioritize the privacy of our
									users and are dedicated to protecting it. Therefore, this
									Privacy Policy outlines the Personal Information we collect,
									the reasons for collecting it, and how we utilize it.
								</div>

								<div className="mb-8" id="overview">
									<h2 className="font-bold mb-3">Overview</h2>
									<div className="mb-5">
										This Privacy Policy describes your privacy rights regarding
										how and when we collect, use, store, share and protect your
										information across our website, payment platforms
										(“Platforms”), APIs, Software Applications, (“Apps”), email
										notifications and tools regardless of how you use or access
										them.
									</div>
									<div className="mb-5">
										This Privacy Policy is applicable to all of the Website,
										software applications (“Apps”) and/or payment platforms
										(“Platforms”) offered by Platnova or its subsidiaries or
										affiliated companies collectively referred to as Platnova
										“services”.
									</div>
									<div className="mb-5">
										Platnova Inc and its affiliates are part of a group which is
										made up of a number of local operating entities in a number
										of markets across the globe. “Platnova”, “we” “us” or our”
										in this privacy statement means the Platnova entity that is
										responsible for processing your personal information.
									</div>
								</div>

								<div className="mb-8" id="definition">
									<h2 className="font-bold mb-3">Definition</h2>
									<div className="mb-5">Account: means a Platnova Account</div>
									<div className="mb-5">
										Cookies: A cookie is a small data file that is transferred
										to your computer or mobile device. It enables us to remember
										your account log-in information, IP addresses, web traffic
										number of times you visit, browser type and version, device
										details, date and time of visits.
									</div>
									<div className="mb-5">
										Platnova means Platnova Inc and subsidiaries or affiliates.
									</div>
									<div className="mb-5">
										Personal Information: Any information that can be used to
										identify a living person including email address, company
										name, password, payment card, financial information such as
										(bank account number, etc.), Government-issued Identity card
										and/or taxpayer identification it may also include anonymous
										information that is linked to you, for example, your
										internet protocol (IP), log-in information, address,
										location, device or transaction data.
									</div>
									<div className="mb-5">
										Sites: means any platform including but not limited to
										mobile applications, websites and social media platforms.
									</div>
									<div className="mb-5">
										User: means an individual who uses the Services or accesses
										the Sites and has agreed to use the end services of
										Platnova.
									</div>
									<div className="mb-5">
										Special Categories of Personal Information means details
										about your race or ethnicity, religious or philosophical
										beliefs, sex life, sexual orientation, political opinions,
										trade union membership, information about your health and
										genetic and biometric data.
									</div>
								</div>

								<div className="mb-8" id="ourPrivacyPrinciples">
									<h2 className="font-bold mb-3">Our Privacy Principles</h2>
									<div className="mb-5">
										Platnova focuses on the following core principles
									</div>
									<ul className="list-disc">
										<li className="mb-5">
											To Empower the individual: Platnova wants you to be in
											charge of your personal information and to make your own
											voluntary choices about your personal data;
										</li>
										<li className="mb-5">
											To keep and secure personal information: Platnova Inc. and
											its affiliates (collectively, “Platnova’) do not take your
											trusting us with your information for granted. We take
											responsibility to ensuring that appropriate security
											measures are put in place and your personal information is
											protected;
										</li>
										<li className="mb-5">
											To be transparent and to educate users: For you to know
											what personal information is, how we collect personal
											information, for what purpose and how we secure personal
											information;
										</li>
										<li className="mb-5">
											To abide by local laws: Platnova is a global company with
											local privacy expertise. Our privacy practices may vary
											among the countries in which we operate to reflect local
											practices and legal requirements. Specific privacy notices
											may apply to some of our products and services. Please
											visit the webpage of the specific product or service to
											learn more about our privacy and information practices in
											relation to that product or service;
										</li>
										<li className="mb-5">
											To collect and store personal information on the “need to
											collect” basis: Platnova collects personal information to
											perform its services for you. We work to have measures in
											place to prevent collecting and storing personal data
											beyond what we need.
										</li>
									</ul>
								</div>

								<div className="mb-8" id="personalInformationWeMayCollect">
									<h2 className="font-bold mb-3">
										Personal Information We May Collect About You
									</h2>
									<div className="mb-5">
										We may collect, use, process, store, or transfer personal
										information such as:
									</div>
									<ul className="list-disc">
										<li className="mb-5">
											Identity Data: Information such as, your full name(s),
											your government-issued identity number, and your date of
											birth. This data is to enable us to verify your identity
											in order to offer our services to you;
										</li>
										<li className="mb-5">
											Contact Data: This is data that is needed to reach out to
											you, such as your contact address, email address,
											telephone number, details of the device you use and
											billing details;
										</li>
										<li className="mb-5">
											Contact List. We may collect your contact list to enhance
											and streamline your airtime and data purchase experience
											within the app.
										</li>
										<li className="mb-5">
											Identification documents :(such as your passport or any
											Government-issued identity card), a photograph (if
											applicable) and any other registration information you may
											provide to prove you are eligible to use our services;
										</li>
										<li className="mb-5">
											Log/Technical information: When you access Platnova
											services, our servers automatically record information
											that your browser sends whenever you visit a website,
											links you have clicked on, length of visit on certain
											pages, unique device identifier, log-in information,
											location and other device details.
										</li>
										<li className="mb-5">
											Financial Data: Information, such as personal account
											number, the merchant’s name and location, the date and the
											total amount of transaction, and other information
											provided by financial institutions or merchants when we
											act on their behalf;
										</li>
										<li className="mb-5">
											Transactional Data: These are information relating to a
											payment when you as a merchant (using one or more of our
											payment processing services) or as a customer, are using
											our products or services;
										</li>
										<li className="mb-5">
											Marketing and Communications Data: This includes both a
											record of your decision to subscribe or to withdraw from
											receiving marketing materials from us or from our third
											parties.
										</li>
										<li className="mb-5">
											Records of your discussions with us, if we contact you and
											if you contact us.
										</li>
									</ul>
									<div>
										We may also collect, store, use and transfer non-personal
										information or anonymized data such as statistical or
										demographic data.
									</div>
									<div className="mb-5">
										As a principle, we do not collect any Special Categories of
										Personal Information. If we do collect Special Categories of
										Personal Information, we will ensure compliance with
										applicable law.
									</div>
									<div className="mb-5">
										This Privacy Policy applies to Platnova services only. We do
										not exercise control over the sites displayed or linked from
										within our various services. These other sites may place
										their own cookies, plug-ins or other files on your computer,
										collect data or solicit personal information from you.
										Platnova does not control these third-party websites and we
										are not responsible for their privacy statements. Please
										consult such third parties’ privacy statements.
									</div>
								</div>

								<div className="mb-8" id="howWeMayGetYourPersonalInfo">
									<h2 className="font-bold mb-3">
										How we get your Personal Information and why we have it
									</h2>
									<div className="mb-5">
										The Personal Information we have about you is directly made
										available to us when you:
									</div>
									<ul className="list-disc">
										<li className="mb-5">Sign up for a Platnova Account;</li>
										<li className="mb-5">Use any of our services;</li>
										<li className="mb-5">Contact our customer support team;</li>
										<li className="mb-5">Fill our online forms;</li>
										<li className="mb-5">Contact us;</li>
									</ul>
									<div className="mb-5">
										The lawful basis we rely on for processing your Personal
										Information are:
									</div>
									<ul className="list-disc">
										<li className="mb-5">
											Your Consent: Where you agree to us collecting your
											Personal Information by using our Services.
										</li>
										<li className="mb-5">
											We have a contractual obligation: Without your Personal
											Information, we cannot provide our Services to you.
										</li>
										<li className="mb-5">
											We have a legal obligation: To ensure we are fully
											compliant with all applicable Financial legislations such
											as Anti-Money Laundering and Counter Terrorist Financing
											Laws, we must collect and store your Personal Information.
											We protect against fraud by checking your identity with
											your Personal Information.
										</li>
									</ul>
								</div>

								<div className="mb-8" id="howWeMayUseYourPersonalInformation">
									<h2 className="font-bold mb-3">
										How We May Use Your Personal Information
									</h2>
									<div className="mb-5">
										We may use your Personal Information we collect to:
									</div>
									<ul className="list-disc">
										<li className="mb-5">
											Create and manage any accounts you may have with us,
											verify your identity, provide our services, and respond to
											your inquiries;
										</li>
										<li className="mb-5">
											Process your payment transactions (including
											authorization, clearing, chargebacks and other related
											dispute resolution activities);
										</li>
										<li className="mb-5">
											Protect against and prevent fraud, unauthorized
											transactions, claims and other liabilities;
										</li>
										<li className="mb-5">
											Provide, administer and communicate with you about
											products, services, offers, programs and promotions of
											Platnova, financial institutions, merchants and partners;
										</li>
										<li className="mb-5">
											Evaluate your interest in employment and contact you
											regarding possible employment with Platnova;
										</li>
										<li className="mb-5">
											Evaluate and improve our business, including developing
											new products and services;
										</li>
										<li className="mb-5">
											As necessary to establish, exercise and defend legal
											rights;
										</li>
										<li className="mb-5">
											To use data analytics to improve our Website, products or
											services, and user experiences.
										</li>
										<li className="mb-5">
											For other purposes for which we provide specific notice at
											the time of collection.
										</li>
									</ul>
								</div>
								<div className="mb-8" id="usageofCollectedContactList">
									<h2 className="font-bold mb-3">
										Usage of Collected Contact List
									</h2>
									<div className="mb-5">
										The collected contact list will be used to enhance and
										streamline your airtime and data purchase experience within
										the app.
									</div>
								</div>

								<div className="mb-8" id="dataSecurityAndRention">
									<h2 className="font-bold mb-3">
										Data Security and Retention
									</h2>
									<div className="mb-5">
										The security of your Personal Information is important to
										Platnova. We are committed to protecting the information we
										collect. We maintain administrative, technical and physical
										controls designed to protect the Personal Information you
										provide, or we collect against loss or theft, as well as
										against any unauthorized access, risk of loss, disclosure,
										copying, misuse or modification.
									</div>
									<div className="mb-5">
										Other security measures include but not limited to, secure
										servers, firewall, data encryption and granting access only
										to employees in order to fulfil their job responsibilities.
									</div>
									<div className="mb-5">
										Where you use a password for any of your Accounts, please
										ensure you do not share this with anyone, and the password
										is kept confidential at all times.
									</div>
									<div className="mb-5">
										We are committed to conducting our business in accordance
										with these principles in order to ensure that the
										confidentiality of your Personal Information is protected
										and maintained. Transmitting information online is not
										entirely secure. As such, we cannot guarantee that all
										information provided online is secure. We would take all
										reasonable steps to ensure that your Personal Information is
										secured and well protected.
									</div>
									<div className="mb-5">
										We will only retain personal information on our servers for
										as long as is reasonably necessary as long as we are
										providing Services to you. Where you close your Account,
										your information is stored on our servers to the extent
										necessary to comply with regulatory obligations and for the
										purpose of fraud monitoring, detection and prevention. Where
										we retain your Personal Data, we do so in compliance with
										limitation periods under the applicable law.
									</div>
								</div>

								<div className="mb-8" id="cookies">
									<h2 className="font-bold mb-3">Cookies</h2>
									<div className="mb-5">
										Like many other websites, we use cookies to distinguish you
										from other users and to customize and improve our services.
									</div>
									<div className="mb-5">
										Some browsers may automatically accept cookies while some
										can be modified to decline cookies or alert you when a
										website wants to place a cookie on your computer. If you do
										choose to disable cookies, it may limit your ability to use
										our website. For detailed information on the cookies and how
										we use them see our Cookie Policy
									</div>
								</div>

								<div className="mb-8" id="updatesToOurPrivacyPolicy">
									<h2 className="font-bold mb-3">
										Updates to our privacy policy
									</h2>
									<div className="mb-5">
										From time to time, we may change, amend or review this
										Privacy Policy from time to time to reflect new services or
										changes in our Privacy Policy and place any updates on this
										page. All changes made will be posted on this page and where
										changes will materially affect you, we will notify you of
										this change by placing a notice online or via mail. If you
										keep using our Services, you consent to all amendments of
										this Privacy Policy.
									</div>
								</div>

								<div className="mb-8" id="contactUs">
									<h2 className="font-bold mb-3">Contact Us</h2>
									<div className="mb-5">
										All access requests, questions, comments, complaints and
										other requests regarding the privacy policy should be sent
										to{" "}
										<a
											href="mailto:support@platnova.com"
											className="text-purple-500 hover:text-purple-600">
											support@platnova.com
										</a>
										.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PrivacyPage;
